import React from "react";
import OrderService from "../../services/OrderService";
import { withTranslation} from "react-i18next";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CustomTable from "../../commons/table/CustomTable";
import IconSorting from "../../commons/table/IconSorting";
import ErrorModal from "../../commons/modal/ErrorModal";
import SpinnerModal from "../../commons/modal/SpinnerModal";
import {formatErrorMessage} from "../../utils/stringUtils";

class OrdersComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nip: "",
            state: "",
            idCrm: "",
            name: "",
            address: "",
            network: "",
            showSpinnerMessage: null,
            showErrorMessage: null,
            orders: [],
            loading: true,
            direction: "asc",
            sorting: null,
            acquisitionType: null
        }
    }

    componentDidMount() {
        this.getData();
    }

    showSpinner = (message) => {
        this.setState({
            showSpinnerMessage: message,
            showErrorMessage: null
        });
    }

    showError = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showErrorMessage: message
        });
    }

    hideSpinner = () => {
        this.setState({
            showSpinnerMessage: null
        });
    }

    hideError = () => {
        this.setState({
            showErrorMessage: null
        })
    }

    localizeOrders = (orders) => {
        orders.forEach((order) => {
            order.orderState = this.props.t('orders:state.' + order.orderState);
            order.locationNetwork = order.locationNetwork === "INDEPENDENT" ? "" : order.locationNetwork;
        });
        return orders;
    }

    getData = (page, count, sorting, direction) => {
        this.showSpinner(this.props.t('common:modalMessage.getData'));
        OrderService.getOrders({
            parametersDTO: {
                page: page,
                count: count,
                sorting: sorting ? sorting : this.state.sorting,
                direction: direction ? direction : this.state.direction
            },
            nip: this.state.nip,
            state: this.state.state,
            idCrm : this.state.idCrm,
            name: this.state.name,
            address: this.state.address,
            network: this.state.network,
            acquisitionType : this.state.acquisitionType ? this.state.acquisitionType : null
        }).then((response) => {
            this.setState({
                showSpinnerMessage: null,
                orders: this.localizeOrders(response.data.list),
                total: response.data.total,
                sorting: sorting ? sorting : this.state.sorting,
                direction: direction ? direction : this.state.direction,
                loading: false
            })
        }, (error) => {
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    handleSorting = (sorting) => {
        this.getData(1, 10, sorting, this.state.direction === "asc" ? "desc" : "asc");
    };

    clearFilter = () => {
        this.setState({
            nip: "",
            state: "",
            idCrm: "",
            name: "",
            address: "",
            network: "",
            acquisitionType: ""
        });
    };

    render() {
        const { t } = this.props;
        const columns = [
            {
                name: <>
                    {t('orders:listColumns.orderId')}
                    <IconSorting sorting={this.state.sorting} selector="orderId" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.id,
                width: '6%'
            },
            {
                name: <>
                    {t('orders:listColumns.companyNip')}
                    <IconSorting sorting={this.state.sorting} selector="companyNip" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.companyNip,
                width: '10%'
            },
            {
                name: <>
                    {t('orders:listColumns.companyIdCrm')}
                    <IconSorting sorting={this.state.sorting} selector="companyIdCrm" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.companyIdCrm,
                width: '7%'
            },
            {
                name: <>
                    {t('orders:listColumns.companyName')}
                    <IconSorting sorting={this.state.sorting} selector="companyName" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.companyName,
                width: '16%'
            },
            {
                name: <>
                    {t('orders:listColumns.locationIdCrm')}
                    <IconSorting sorting={this.state.sorting} selector="locationIdCrm" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.locationIdCrm,
                width: '8%'
            },
            {
                name: <>
                    {t('orders:listColumns.locationName')}
                    <IconSorting sorting={this.state.sorting} selector="locationName" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.locationName,
                width: '9%'
            },
            {
                name: <>
                    {t('orders:listColumns.locationCity')}
                    <IconSorting sorting={this.state.sorting} selector="locationCity" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.locationCity,
                width: '11%'
            },
            {
                name: <>
                    {t('orders:listColumns.locationStreet')}
                    <IconSorting sorting={this.state.sorting} selector="locationStreet" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.locationStreet,
                width: '9%'
            },
            {
                name: <>
                    {t('orders:listColumns.locationNetwork')}
                    <IconSorting sorting={this.state.sorting} selector="locationNetwork" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.locationNetwork,
                width: '9%'
            },
            {
                name: <>
                    {t('orders:listColumns.orderState')}
                    <IconSorting sorting={this.state.sorting} selector="orderState" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => <>
                    <NavLink to={'/orders/' + row.id}>
                        {row.orderState}
                    </NavLink>
                </>,
                width: '9%'
            },
            {
                name: <>
                    {t('orders:listColumns.acquisitionType')}
                    <IconSorting sorting={this.state.sorting} selector="acquisitionType" direction={this.state.direction} handleSorting={this.handleSorting} count={this.state.count}  />
                </>,
                cell: (row) => row.acquisitionType,
                width: '6%'
            }
        ];
        return(
            <>
                <ErrorModal modalShow={this.state.showErrorMessage != null} close={this.hideError} body={this.state.showErrorMessage} title={t('common:modalMessage.errorTitle')}/>
                <SpinnerModal modalShow={this.state.showSpinnerMessage != null} title={this.state.showSpinnerMessage}/>
                <Container className="customForm">
                    <h4>{t('orders:header')}</h4>
                    <Row>
                        <Col md={3}>
                            <Col md={12}>
                                <Form.Label className="labelName">{t('orders:filters.nip')}</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control type="text" name="nip" value={this.state.nip} onChange={(event) => {this.handleInputChange(event)}}/>
                            </Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}>
                                <Form.Label className="labelName">{t('orders:filters.state')}</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Select name="state" value={this.state.state} onChange={(event) => {this.handleInputChange(event)}} >
                                    <option value={""}>{t('orders:state.any')}</option>
                                    <option value={"CREATED"}>{t('orders:state.CREATED')}</option>
                                    <option value={"ASSIGNED"}>{t('orders:state.ASSIGNED')}</option>
                                    <option value={"STARTED"}>{t('orders:state.STARTED')}</option>
                                    <option value={"SUSPENDED"}>{t('orders:state.SUSPENDED')}</option>
                                    <option value={"CLOSED"}>{t('orders:state.CLOSED')}</option>
                                    <option value={"RESIGNED"}>{t('orders:state.RESIGNED')}</option>
                                </Form.Select>
                            </Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}>
                                <Form.Label className="labelName">{t('orders:filters.idCrm')}</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control type="text" name="idCrm" value={this.state.idCrm} onChange={(event) => {this.handleInputChange(event)}}/>
                            </Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}>
                                <Form.Label className="labelName">{t('orders:filters.network')}</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control type="text" name="network" value={this.state.network} onChange={(event) => {this.handleInputChange(event)}}/>
                            </Col>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <Col md={12}>
                                <Form.Label className="labelName">{t('orders:filters.name')}</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control type="text" name="name" value={this.state.name} onChange={(event) => {this.handleInputChange(event)}}/>
                            </Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}>
                                <Form.Label className="labelName">{t('orders:filters.address')}</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control type="text" name="address" value={this.state.address} onChange={(event) => {this.handleInputChange(event)}}/>
                            </Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}>
                                <Form.Label className="labelName">{t('orders:filters.acquisitionType')}</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Select name="acquisitionType" value={this.state.acquisitionType} onChange={(event) => {this.handleInputChange(event)}} >
                                    <option value={""}>{t('orders:state.any')}</option>
                                    <option value={"RAD3"}>{"RAD3"}</option>
                                    <option value={"DRS"}>{"DRS"}</option>
                                    <option value={"APD"}>{"APD"}</option>
                                </Form.Select>
                            </Col>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={12}>
                            <Button onClick={() => this.getData(1, this.state.count)} variant="primary">{t('common:button.filter')}</Button>
                            <span style={{ margin: '0 10px' }}></span>
                            <Button onClick={() => this.clearFilter()} variant="warning">{t('common:button.clear')}</Button>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                    </Row>
                    <Row>
                        <Col md={12} className="customTable">
                            <CustomTable data={this.state.orders} columns={columns}
                                         paginationServer={true}
                                         getData={this.getData}
                                         totalRows={this.state.total}
                                         loading={this.state.loading}
                                         selectableRows={false}
                                         fixedHeader={false}
                                         showHeader={false}/>
                        </Col>
                    </Row>
                    <br/>
                </Container>
            </>
        )
    }
}

export default withTranslation()(OrdersComponent);